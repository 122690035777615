import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Banner from '../Components/Home/Banner/Banner';
import About from '../Components/Home/About/About';
import BoostModule from '../Components/Home/AboutBoost/BoostModule';
import FullWidthTile from '../Components/Home/AboutBoost/FullWidthTile';
import RightTile from '../Components/Home/MortageServices/RightTile';
import LeftTile from '../Components/Home/WorkNumber/LeftTile';
import Insights from '../Components/Home/Insights/Insights';
import ImageGrid from '../Components/Home/About/ImageGrid';
import PropertyWorthModule from '../Components/how-much-property-worth-module';
import AuctionModule from '../Components/auction-blue-module';
import BlgoresTop from '../Components/StaticModules/AboutPage/About';
const Index=(props)=>{
    const GQLPage = props.GQLPage;
    const GQLModules = props.GQLModules;
    const pageclass = GQLPage.Page_Class ? GQLPage.Page_Class:"";

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("touchmove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
      })
  
    }, [])
    
    return (
        <React.Fragment>
            <div id={`body-bg-${GQLPage.Body_Background_Color?GQLPage.Body_Background_Color:"default"}`} className={(GQLPage.Select_Template === "Landing_Page")?"wrapper "+pageclass:(GQLPage.Select_Template === "Static_Page")?"wrapper static-body property-wrap "+pageclass:pageclass}>
            <div className={GQLPage.Select_Template==="Static_Page"?"inner-header":"home-header"}>
            <Header title={GQLPage.Title} pageclass={pageclass} GQLConfig={props.GQLConfig} renderComponent={renderComponent}/>
            </div>
            {GQLModules.map((Modules, i) => {
                return<>
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBannerSlider" &&
                    <Banner slides={Modules.Banner_Slides} GQLConfig={props.GQLConfig} Show_Holidays_Banner={props.GQLPage.Show_Holidays_Banner} renderComponent={renderComponent}/>
                }    
                {Modules.__typename === "GLSTRAPI_ComponentComponentsShortIntro" &&
                    <About title={Modules.Short_Intro_Title} content={Modules.Short_Intro_Content} member={Modules.Select_Member} id={Modules.Section_Id} cta= {Modules.CTA} appLinks = {Modules?.App_Links} renderComponent={renderComponent}/>
                }
                 {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "How_Much_Is_Your_Property_Worth_Module" &&
                    <PropertyWorthModule title={GQLPage.Title} renderComponent={renderComponent}/>
                }
                 {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Auction_Module" &&
                    <AuctionModule renderComponent={renderComponent}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsBalgoresBoostTop" &&                
                <BlgoresTop leftcontent={Modules.Balogres_Boost_Top_Left_Content} videourl={Modules.Balogres_Boost_Top_Right_Video_URL} rightimage={Modules.Balogres_Boost_Top_Right_Image} counter={Modules.Balgores_Boost_Counter} renderComponent={renderComponent}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsImageGrids" &&
                    <ImageGrid grids={Modules.Grid_List} section_title= {Modules.Section_Title} intro_text= {Modules.Section_Introtext} renderComponent={renderComponent}/>
                }
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Balgores_Boost_Module" &&
                    <BoostModule renderComponent={renderComponent}/>
                } 
               {Modules.__typename === "GLSTRAPI_ComponentComponentsTileBlocks" &&
                    <>
                    {Modules.Image_Position === "Right" && Modules.Tile_Style !=="Full_Width" &&
                        <RightTile id={Modules.id} video={Modules.Tile_Youtube_Video_URL} accordion={Modules.Tile_Accordion}bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image} renderComponent={renderComponent}/>
                    }
                    {Modules.Image_Position === "Left" && Modules.Tile_Style !=="Full_Width" &&
                        <LeftTile  video={Modules.Tile_Youtube_Video_URL} bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image} counter={Modules.Tile_Counter} renderComponent={renderComponent}/>
                    }
                    {Modules.Image_Position === "Left" && Modules.Tile_Style ==="Full_Width" &&
                        <FullWidthTile video={Modules.Tile_Youtube_Video_URL} bgcolor={Modules.Tile_Background} style={Modules.Tile_Style} title={Modules.Tile_Title} content={Modules.Tile_Content} customlink={Modules.Tile_Custom_Link} label={Modules.Tile_Link_Label} link={Modules.Tile_Link} image={Modules.Tile_Image} renderComponent={renderComponent}/>
                    }
                    </>
                }               
              
                {Modules.__typename === "GLSTRAPI_ComponentComponentsModules" && Modules.Select_Module === "Insights_Slider_Module" && renderComponent &&
                    <Insights renderComponent={renderComponent}/>
                }
               
                {/* Listing Modules End */}
                </>
             })}
            <Footer popularSearch={GQLPage.Select_Popular_Search} GQLConfig={props.GQLConfig} renderComponent={renderComponent}/>
            </div>
        </React.Fragment>
    )
}
export default Index;
